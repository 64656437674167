<template>
  <div class="edit-integration-page">
    <BackTitle confirm-click @click="goBack"> Edit integration </BackTitle>
    <Guard permission="integrations.edit" v-slot="{ isAvailable }">
      <IntegrationForm
        v-model="integration"
        :isSubmitting="isSubmitting"
        :withDelete="true"
        :isDisabled="!isAvailable"
        @submit="save"
        @remove="remove"
      />
    </Guard>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle.vue";
import { mapActions, mapState } from "vuex";
import IntegrationForm from "@/components/integrations/IntegrationForm";
import Guard from "@/components/common/Guard";

export default {
  name: "EditIntegrationPage",
  components: { BackTitle, IntegrationForm, Guard },
  data() {
    return {
      isSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      integration: (state) => state.integrations.editedItem,
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchIntegrationById(this.$route.params.id);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      updateIntegration: "integrations/updateIntegration",
      fetchIntegrationById: "integrations/fetchIntegrationById",
      deleteIntegration: "integrations/deleteIntegration",
    }),
    goBack() {
      this.$router.push({
        name: "Integrations",
      });
    },
    async save(data) {
      try {
        this.isSubmitting = true;
        await this.updateIntegration({ id: this.integration.id, data });
        this.$router.push({
          name: "Integrations",
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    async remove() {
      await this.deleteIntegration(this.integration.id);
      this.$router.push({
        name: "Integrations",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.edit-integration-page {
  display: flex;
  flex-direction: column;
  gap: 40px;
  height: 100%;
}
</style>
